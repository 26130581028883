import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Loadable from '@loadable/component'
const ProductModalButton = Loadable(() =>
  import('../components/productModalButton')
)
const ProductModalImage = Loadable(() =>
  import('../components/productModalImage')
)

import './discover-products.scss'

export default function CuratedLists() {
  // For Google Analytics
  const trackUserEvent = (product, company, discount, price, category) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'discoverProduct',
      productCategory: category,
      product: product,
      company: company,
      discount: discount,
      price: price,
    })
  }
  const data = useStaticQuery(graphql`
    {
      cl: contentfulCuratedListsPage(
        contentful_id: { eq: "3fX1ZYH8Rm2Mx7fnIWEbI1" }
      ) {
        id
        externalTitle
        seoDescription
        tagline
        codesLastUpdated(formatString: "D MMM YYYY")
        image {
          description
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        intro {
          json
        }
        content {
          ... on ContentfulCuratedList {
            id
            externalTitle
            internal {
              type
            }
            description {
              json
            }
            image {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            imageAlignment
            content {
              ... on ContentfulProduct {
                id
                externalTitle
                brand
                price
                productDescription {
                  json
                }
                image {
                  ... on ContentfulAsset {
                    id
                    description
                    fluid(maxWidth: 200, quality: 100) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                }
                productUrl
                vendorName
                price
                discountCode
                discountNumber
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        bodyAttributes={{ class: 'curated-lists' }}
        title={data.cl.externalTitle}
        description={data.cl.seoDescription}
      />
      <section className="cl-hero">
        <div className="cl-hero-copy">
          <h1 className="cl-heading">{data.cl.externalTitle}</h1>
          <div className="cl-tagline">{data.cl.tagline}</div>
          <div className="cl-updated">
            Last time updated: {data.cl.codesLastUpdated}{' '}
          </div>
        </div>
        <div className="cl-hero-image">
          <Img fluid={data.cl.image.fluid} alt={data.cl.image.description} />
        </div>
      </section>

      <section className="cl-intro">
        <div>{documentToReactComponents(data.cl.intro.json)}</div>
      </section>

      {data.cl.content.map((item) => {
        return (
          <section key={item.id} className="cl-list">
            <div className="cl-list-info">
              <div className="cl-list-info-copy">
                <h2>{item.externalTitle}</h2>

                {item.description && (
                  <div className="cl-list-description">
                    {documentToReactComponents(item.description.json)}
                  </div>
                )}
              </div>
              <div className="cl-list-image-wrapper">
                {item.image && (
                  <Img
                    fluid={item.image.fluid}
                    alt={item.image.description}
                    className={`cl-list-image ${item.imageAlignment}`}
                  />
                )}
              </div>
            </div>

            <div className="cl-list-products">
              {item.content &&
                item.content.map((product) => {
                  return (
                    <div className="cl-product" key={product.id}>
                      {product.discountCode ? (
                        <div className="product-discount">
                          <div className="product-discount-label">
                            Floof Family Discount
                          </div>
                          <div className="product-discount-number">
                            {product.discountNumber}% off
                          </div>
                        </div>
                      ) : (
                        <div className="product-discount">&nbsp;</div>
                      )}

                      {product.image.map((img) => {
                        return (
                          <div
                            key={img.id}
                            className="cl-product-image-wrapper"
                          >
                            {product.discountCode ? (
                              <ProductModalImage
                                productName={product.externalTitle}
                                productDiscount={product.discountNumber}
                                productCode={product.discountCode}
                                productUrl={product.productUrl}
                                vendor={product.vendorName}
                                fluid={img.fluid}
                                alt={img.description}
                              ></ProductModalImage>
                            ) : (
                              <a href={product.productUrl} target="_blank">
                                <Img fluid={img.fluid} alt={img.description} />
                              </a>
                            )}
                            <h3 className="cl-product-title">
                              {product.externalTitle}
                            </h3>
                            <div className="cl-product-brand">
                              {product.brand}
                            </div>
                            <div className="cl-product-price">
                              ${product.price}
                            </div>
                            {product.discountCode ? (
                              <ProductModalButton
                                productName={product.externalTitle}
                                productDiscount={product.discountNumber}
                                productCode={product.discountCode}
                                productUrl={product.productUrl}
                                vendor={product.vendorName}
                                price={product.price}
                                fluid={img.fluid}
                                alt={img.description}
                                productCategory={item.externalTitle}
                              ></ProductModalButton>
                            ) : (
                              <div className="btn cl-product">
                                <a
                                  href={product.productUrl}
                                  target="_blank"
                                  onClick={() => {
                                    trackUserEvent(
                                      product.externalTitle,
                                      product.vendorName,
                                      product.discountCode,
                                      product.price,
                                      item.externalTitle
                                    )
                                  }}
                                >
                                  Buy it
                                </a>
                              </div>
                            )}{' '}
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
            </div>
          </section>
        )
      })}
    </Layout>
  )
}
